if (typeof Swup !== "undefined") {
    const swup = new Swup({
        containers: ['#layout_main', '#layout_header'],
        linkSelector:
            'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([target="_blank"]), a[href^="/"]:not([data-no-swup]):not([target="_blank"]), a[href^="#"]:not([data-no-swup])'
    });

    swup.on('clickLink', function () {
        document.body.classList.remove("overflow--hidden");

        if (document.querySelector(".drawer-active") !== null) {
            application["getControllerForElementAndIdentifier"](document.body, "lib-drawer").hide()
        }

        if (document.querySelector(".lib--dialog") !== null) {
            LibDialog.hide();
        }
    });

    document.addEventListener('swup:animationOutDone', () => {
        window.scrollTo(0, 0);

        if (typeof window.swupActive === "undefined") {
            window.swupActive = true;
        }
    });

    swup.on('contentReplaced', function () {
        if (typeof locomotive !== "undefined") {
            locomotive.destroy();
            document.getElementById("layout_scroll").removeAttribute("style");
            if (document.querySelector("[data-no-locomotive]") === null) {
                locomotive.init();
            }
        }

        swup.options.containers.forEach((selector) => {
            loadStimulus(document.querySelector(selector));
        });

        LibAnchor.init();

        if (typeof fbq !== "undefined") {
            fbq('track', 'PageView');
        }

        if (typeof gtag !== "undefined") {
            let configs = [];
            window.dataLayer.forEach(function (config) {
                if (config[0] === "config") {
                    if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                        configs.push(config[1]);
                        gtag('config', config[1], {
                            'page_title': document.title,
                            'page_path': window.location.pathname + window.location.search
                        });
                    }
                }
            });
        }

        if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push({
                'event': 'VirtualPageview',
                'virtualPageURL': window.location.pathname + window.location.search,
                'virtualPageTitle': document.title
            });
        }

        if (typeof window.seznam_retargeting_id !== "undefined") {
            importScript("https://c.imedia.cz/js/retargeting.js");
        }

        if(typeof window.luiCookieConsentInit !== 'undefined') {
            window.luiCookieConsentInit()
        }

        document.body.classList.remove("body--preload");
        document.body.classList.add("body--loaded");
    });
}
