application.register("comp_calculator", class extends Stimulus.Controller {

    get target() {
        return {
            settings: this.targets.find("settings"),
            results: this.targets.find("results"),
        }
    }

    connect() {
        this._type = "forward";
        this._values = {};
        this.calculate();

        importScript([cdnjs.noui, cdnjs.cleave],() => {
            this.getTargetAll("settingsItem").forEach((item) => {
                let options = JSON.parse(item.dataset["settingOptions"]);
                let rangeSlider = item.querySelector(`[data-setting-target="slider"]`);
                let input = item.querySelector(`[data-setting-target="input"]`);

                if(rangeSlider !== null) {
                    noUiSlider.create(rangeSlider, {
                        start: [options.value],
                        step: options.step,
                        connect: [true, false],
                        range: {
                            "min": options.min,
                            "max": options.max,
                        }
                    });

                    if(input !== null) {
                        rangeSlider.noUiSlider.on("update", (values, handle) => {
                            input.value = parseInt(values[handle]).toLocaleString(lang);
                        });

                        rangeSlider.noUiSlider.on("change", (values, handle) => {
                            input.dispatchEvent(new Event('change', { 'bubbles': true, 'cancelable': true }));
                        });

                        input.addEventListener("change", () => {
                            rangeSlider.noUiSlider.set(parseInt(input.value.replace(/\s/g, "")));
                        });
                    }
                }

                if(input !== null) {
                    new Cleave(input,{
                        numeral: true,
                        numeralThousandsGroupStyle: 'thousand',
                        delimiter: " "
                    });
                }
            });
        });
    }

    setStrategy(e) {
        if(e.currentTarget.classList.contains("state--active")) {
            return;
        }

        //set type
        let type = e.currentTarget.dataset["actionType"];
        let valuation = parseInt(e.currentTarget.dataset["valuation"]);

        //set active button
        let active = this.getTarget("nav").querySelector(".state--active");
        if(active !== null) {
            active.classList.remove("state--active");
        }
        e.currentTarget.classList.add("state--active");
        this.getTargetAll("description").forEach((item)=>{
            item.classList.remove("state--active");
            if(item.dataset["targetType"] === type) {
                item.classList.add("state--active");
            }
        });

        //set inputs
        let valuationInput = this.getTargetAll("input").find(input => input.name === "evaluationPercent");
        this.getTarget("strategy").value = type;

        this.target.settings.classList.remove("type--custom");
        switch (type) {
            case "dynamic":
                valuationInput.value = valuation;
                break;
            case "conservative":
                valuationInput.value = valuation;
                break;
            case "custom":
                this.target.settings.classList.add("type--custom");
                valuationInput.value = valuation;
                break;
            default:
                break;
        }
        valuationInput.dispatchEvent(new Event('change', { 'bubbles': true, 'cancelable': true }));

        if(typeof locomotive !=="undefined") {
            locomotive.update();
        }
    }

    setType(e) {
        this._type = e.currentTarget.value;

        this.target.settings.classList.remove("type--reverse","type--forward");
        this.target.settings.classList.add(`type--${this._type}`);
        this.target.results.classList.remove("type--reverse","type--forward");
        this.target.results.classList.add(`type--${this._type}`);

        this.calculate();

        if(typeof locomotive !=="undefined") {
            locomotive.update();
        }
    }

    sendForm(e) {
        e.preventDefault();
    }

    calculate() {
        this._values = {};
        let results = {};
        let resultsOther = {};
        let contributionClientReverse = 0;

        this.getTargetAll("input").forEach((input) => {
            this._values[input.name] = parseInt(input.value.replace(/\s/g, ""));
        });

        if(this._type === "forward") {
            results = this.calculateForward(this._values["age"],this._values["startDeposit"],this._values["contributionClient"],this._values["contributionEmployer"],this._values["evaluationPercent"], true);
            resultsOther = this.calculateForward(this._values["age"],this._values["startDeposit"],this._values["contributionClient"],this._values["contributionEmployer"],this._values["evaluationPercent"], false);
        }

        if(this._type === "reverse") {
            contributionClientReverse = this.calculateReverse(this._values["age"],this._values["startDeposit"],this._values["rentDurationYears"],this._values["monthlyRentValue"],this._values["evaluationPercent"]);

            if(contributionClientReverse < 100) {
                contributionClientReverse = 100;
            }

            results = this.calculateForward(this._values["age"],this._values["startDeposit"],contributionClientReverse,0,this._values["evaluationPercent"], true);
            resultsOther = this.calculateForward(this._values["age"],this._values["startDeposit"],contributionClientReverse,0,this._values["evaluationPercent"], false);
        }

        //nastavit vysku grafu a vypsat hovnoty
        this.getTarget("totalRentea").innerHTML = results.total.toLocaleString(lang);
        this.getTarget("totalOther").innerHTML = resultsOther.total.toLocaleString(lang);
        this.getTarget("totalMonthly").innerHTML = contributionClientReverse.toLocaleString(lang);
        this.getTarget("totalYears").innerHTML = 65 - this._values["age"];
        this.getTarget("yearsText").innerHTML = 65 - this._values["age"] === 1 ? 'roku' : 'let';
        this.getTarget("contributionClientReverse").value = contributionClientReverse;


        for(let key in results) {
            let bar = this.getTarget("bar").querySelector(`[data-name="${key}"]`);
            if(bar !== null) {
                bar.style.display = null;

                if(results[key] === 0) {
                    bar.style.display = "none";
                }
                else {
                    bar.style.height = (results[key]*100)/results.total + "%";
                }

            }

            let valueText = this.getTarget("legend").querySelector(`[data-name="${key}"]`);
            if(valueText !== null) {
                valueText.closest(".elm_legend_item").style.display = null
                if(results[key] === 0) {
                    valueText.closest(".elm_legend_item").style.display = "none";
                }
                else {
                    valueText.innerHTML = results[key].toLocaleString(lang);
                }
            }
        }

        if(typeof locomotive !=="undefined") {
            locomotive.update();
        }
    }

    calculateForward(age, startDeposit, contributionClient, contributionEmployer, evaluationPercent, includeBonus = true) {
        let years = 65 - age;
        let months = years * 12;

        let totalClient = 0;
        let totalEmployer = 0;
        let totalState = 0;
        let totalEvaluation = 0;
        let totalRenteaBonus = 0;

        let total = 0;
        let renteaBonusSubTotal = 0;

        let monthCoefficient = Math.pow(1 + evaluationPercent / 100, 1 / 12) - 1;
        let contributionState = contributionClient >= 300 ? 90 + Math.min(700, contributionClient - 300) * 0.2 : 0;

        total += startDeposit;

        for (let x = 1; x <= months; x++) {
            totalClient += contributionClient;
            totalEmployer += contributionEmployer;
            totalState += x % 3 === 0 ? contributionState * 3 : 0;

            total += contributionClient;
            total += contributionEmployer;
            total += x % 3 === 0 ? contributionState * 3 : 0;

            let evaluation = total * monthCoefficient;
            totalEvaluation += evaluation;
            total += evaluation;
            renteaBonusSubTotal += total;

            if (x % 60 === 0 && includeBonus) { // rentea bonus each 5th year 1%
                let guarantee = Math.max(0.01,(((evaluationPercent/100)-0.05)*0.15/2*5).toPrecision(5));
                let renteaBonus = renteaBonusSubTotal / 60 * guarantee;
                totalRenteaBonus += renteaBonus;
                total += renteaBonus;
                renteaBonusSubTotal = 0;
            }
        }

        return {
            startDeposit: parseInt(startDeposit.toFixed(0)),
            totalClient: parseInt(totalClient.toFixed(0)),
            totalEmployer: parseInt(totalEmployer.toFixed(0)),
            totalState: parseInt(totalState.toFixed(0)),
            totalEvaluation: parseInt(totalEvaluation.toFixed(0)),
            totalRenteaBonus: parseInt(totalRenteaBonus.toFixed(0)),
            total: parseInt(total.toFixed(0)),
        }
    }

    calculateReverse(age, startDeposit, rentDurationYears, monthlyRentValue, evaluationPercent) {
        let monthCoefficient = Math.pow(1 + evaluationPercent / 100, 1 / 12) - 1;
        let discountMonth = 1/(1+monthCoefficient);

        let totalNeeded = monthlyRentValue*(1-Math.pow(discountMonth,rentDurationYears*12))/(1-discountMonth);

        let contributionClient = 0;
        let total = 0;

        for (let a = 0; a <= 1000000; a += 100000) {
            contributionClient = a;
            total = this.calculateForward(age, startDeposit, contributionClient, 0, evaluationPercent).total

            if (total > totalNeeded) {
                for (let b = 0; b <= 100000; b += 10000) {
                    contributionClient = a + b - 100000;
                    total = this.calculateForward(age, startDeposit, contributionClient, 0, evaluationPercent).total

                    if (total > totalNeeded) {
                        for (let c = 0; c <= 10000; c += 1000) {
                            contributionClient = a + b + c - 110000;
                            total = this.calculateForward(age, startDeposit, contributionClient, 0, evaluationPercent).total

                            if (total > totalNeeded) {
                                for (let d = 0; d <= 1000; d += 100) {
                                    contributionClient = a + b + c + d - 111000;
                                    total = this.calculateForward(age, startDeposit, contributionClient, 0, evaluationPercent).total

                                    if (total > totalNeeded) {
                                        for (let e = 0; e <= 100; e += 10) {
                                            contributionClient = a + b + c + d + e - 111100;
                                            total = this.calculateForward(age, startDeposit, contributionClient, 0, evaluationPercent).total

                                            if (total > totalNeeded) {
                                                for (let f = 0; f <= 10; f += 1) {
                                                    contributionClient = a + b + c + d + e + f - 111110;
                                                    total = this.calculateForward(age, startDeposit, contributionClient, 0, evaluationPercent).total

                                                    if (total > totalNeeded) {
                                                        return contributionClient;
                                                    }
                                                }
                                                return contributionClient + 10;
                                            }
                                        }
                                        return contributionClient + 100;
                                    }
                                }
                                return contributionClient + 1000;
                            }
                        }
                        return contributionClient + 10000;
                    }
                }
                return contributionClient + 100000;
            }
        }
    }
});