application.register("part_graph", class extends Stimulus.Controller {
    get target() {
        return {
            graph: this.targets.find("graph")
        }
    }

    connect() {
        const self = this;

        inView(self.element, ()=> {
            importScript(cdnjs.chart, () => {
                let chart;
                let data = JSON.parse(self.target.graph.getAttribute("data-graph-values"));
                let datasets = [];

                data.values.forEach((value, index) => {
                    let gradient = null;
                    let width = null;
                    let height = null;
                    let pointRadius = [];

                    value.forEach((val, i) => {
                        if(document.documentElement.clientWidth < 768) {
                            if(i === 0 || i === value.length - 1) {
                                pointRadius.push(6)
                            } else {
                                if(i % 2 === 0) {
                                    pointRadius.push(6)
                                } else {
                                    pointRadius.push(0)
                                }
                            }
                        } else {
                            pointRadius.push(6)
                        }
                    });

                    datasets.push({
                        backgroundColor: function(context) {
                            let chartArea = context.chart.chartArea;

                            if (!chartArea) {
                                // This case happens on initial chart load
                                return null;
                            }


                            let chartWidth = chartArea.right - chartArea.left;
                            let chartHeight = chartArea.bottom - chartArea.top;

                            if (gradient === null || width !== chartWidth || height !== chartHeight) {
                                width = chartWidth;
                                height = chartHeight;
                                let ctx = context.chart.ctx;
                                gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                                gradient.addColorStop(1, "rgba(" + data.color[index] + ",0.3)");
                                gradient.addColorStop(0, "rgba(" + data.color[index] + ",0)");
                            }

                            return gradient;
                        },
                        pointBorderWidth: 4,
                        pointRadius: pointRadius,
                        lineTension: 0,
                        borderColor: 'rgb(189,214,48)',
                        borderWidth: 8,
                        pointBackgroundColor: 'rgb(255,255,255)',
                        pointBorderColor: 'rgb(82,149,70)',
                        pointHoverBackgroundColor: 'rgb(255, 255, 255)',
                        pointHoverBorderWidth: 4,
                        pointHoverRadius: 10,
                        data: data.values[index],
                        fill: true,
                    })
                });

                chart = new Chart(self.target.graph, {
                    type: 'line',
                    data: {
                        labels: data.labels,
                        datasets: datasets
                    },
                    options: {
                        responsive: true,
                        aspectRatio: document.documentElement.clientWidth < 768 ? 0.85 : 2,
                        title: {
                            display: false
                        },
                        hover: {
                            mode: 'single'
                        },
                        onHover: function(e, activePoints) {
                            let tooltip = document.querySelector(".elm_part_tooltip");

                            if (activePoints.length > 0 && typeof data.data !== "undefined") {
                                let tooltipData = data.data[activePoints[0]._datasetIndex][activePoints[0]._index];

                                if (typeof tooltipData === "undefined") {
                                    return false;
                                }

                                tooltip.innerHTML = "";
                                tooltipData.forEach((item) => {
                                    tooltip.insertAdjacentHTML("beforeend", `
                                    <div class="col">
                                        <div class="elm_title"><strong>${item.date}</strong></div>
                                        <div class="elm_title">${data.lang.price}: <strong>${item.price}</strong></div>
                                        <div class="elm_title">${data.lang.valuation}: <strong>${item.valuation}</strong></div>
                                    </div>
                                `);
                                });

                                tooltip.style.left = (e.clientX) + 'px';
                                tooltip.style.top = (((typeof locomotive.scroll.instance.delta !== "undefined") ? locomotive.scroll.instance.delta.y : 0) + e.clientY - (document.documentElement.clientWidth < 768 ? 100 : 120)) + "px";
                                tooltip.style.display = "flex";

                            } else {
                                tooltip.style.display = "none";
                            }
                        },
                        tooltips: {
                            enabled: false
                        },
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                display: true,
                                gridLines: {
                                    display: false,
                                },
                                ticks: {
                                    fontSize: 13,
                                    fontFamily: 'source-sans-pro, serif',
                                    fontColor: '#545454',
                                    fontStyle: 700,
                                    padding: 16
                                }
                            }],
                            yAxes: [{
                                display: true,
                                gridLines: {
                                    color: "rgba(54,28,16,0.2)",
                                    drawBorder:false,
                                    display: true,
                                    zeroLineColor: 'transparent'
                                },
                                ticks: {
                                    fontSize: 13,
                                    fontFamily: 'source-sans-pro, serif',
                                    fontColor: '#545454',
                                    fontStyle: 700,
                                    padding: 16,
                                    precision: 4,
                                    callback: value => {
                                        return value.toFixed(4);
                                    },
                                }
                            }]
                        }
                    }
                });
            });
        })
    }
});
