application.register("comp_calculator_dialog", class extends Stimulus.Controller {

    connect() {
        let self = this;
        let arr = [
            'strategy',
            'age',
            'contributionClient',
            'contributionClientReverse',
            'contributionEmployer',
            'monthlyRentValue',
            'rentDurationYears',
            'startDeposit',
            'evaluationPercent'
        ];

        arr.forEach(function (item) {
            self.element.querySelector(`input[name=${item}]`).value = document.querySelector(`#calculator_form input[name=${item}]`).value;
        });

        self.element.querySelector(`input[name=calcType]`).value = document.querySelector(`#calculator_form input[name=calcType]:checked`).value;
    }
});