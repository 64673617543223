application.register("lib-tippy", class extends Stimulus.Controller {
    connect() {
        importStyle(cdnjs.tippy_css);
        importScript([cdnjs.popper, cdnjs.tippy], () => {
            this.generateTippy(this.element);
        });
    }

    generateTippy(element) {
        tippy(element, {
            allowHTML: true,
            content: element.getAttribute("aria-label"),
            placement: (window.innerWidth > 768) ? "top-start" : "top",
            animation: "shift-away",
            followCursor: false,
            inertia: true,
            maxWidth: 286,
            offset: [-12,12],
        });
    }
});