application.register("part_video", class extends Stimulus.Controller {
    get target() {
        return {
            video: this.targets.find("video")
        }
    }

    toggleMute() {
        this.element.classList.toggle("state--muted", !this.target.video.muted);
        this.target.video.muted = !this.target.video.muted;
    }
});