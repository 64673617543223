application.register("lib-expand", class extends Stimulus.Controller {
    connect() {
        this._isInitialized = false;
    }

    expand(element) {
        let body = element.querySelector("[data-lib-expand-body]");
        body.style.height = body.scrollHeight + "px";
        element.classList.add("state--active");

        if(this.element.closest("[data-lib-expand-group]") !== null) {
            [...this.element.closest("[data-lib-expand-group]").querySelectorAll(`[data-controller*="lib-expand"]`)].forEach((item)=>{
                if(item !== this.element) {
                    this.collapse(item);
                }
            });
        }

        setTimeout(() => {
            body.offsetHeight;
            body.style.height = "auto"
        },300);

        this.updateLocomotive();
    }

    collapse(element) {
        let body = element.querySelector("[data-lib-expand-body]");

        body.style.height = body.offsetHeight + "px";
        body.offsetHeight;
        body.style.height = "0px";
        element.classList.remove("state--active");

        this.updateLocomotive();
    }

    updateLocomotive() {
        if (typeof locomotive !== "undefined" && !locomotive.isMobile) {
            setTimeout(() => {
                locomotive.update();
            }, 350);
        }
    }

    toggle() {
        let body = this.element.querySelector("[data-lib-expand-body]");

        if (body.offsetHeight === 0) {
            this.expand(this.element);
        } else {
            this.collapse(this.element);
        }
    }
});
